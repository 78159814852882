import { ReactComponent as YourSvg } from './../icons/washing-machine-svgrepo-com.svg'
import { ReactComponent as DryCleaningIcon } from './../icons/dry-cleaning.svg'
import { ReactComponent as HandWashIcon } from './../icons/hand-wash.svg'
import { ReactComponent as HouseholdIcon } from './../icons/household.svg'

export default () => {
    return(
        <>
        <section id="service" className="services-area pt-120">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="section-title text-center pb-40">
                            <div className="line m-auto"></div>
                            <h3 className="title">Clean and Fresh, <span> We handle your laundry with care!</span></h3>
                        </div> 
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-6 col-sm-8">
                        <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                            <div className="services-icon">
                                <img className="shape" src="assets/images/services-shape.svg" alt="shape" />
                                <img className="shape-1" src="assets/images/services-shape-4.svg" alt="shape" />
                                <i><YourSvg style={{width:"40"}} /></i>
                            </div>
                            <div className="services-content mt-30">
                                <h4 className="services-title"><a href="#">Normal Wash</a></h4>
                                
                                <p className="text">Perfect for your everyday laundry needs, including shirts, pants, towels, bedding, and more.</p>
                                {/* <a className="more" href="#">Learn More <i className="lni lni-chevron-right"></i></a> */}
                                
                            </div>
                        </div> 
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-8">
                        <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
                            <div className="services-icon">
                                <img className="shape" src="assets/images/services-shape.svg" alt="shape" />
                                <img className="shape-1" src="assets/images/services-shape-1.svg" alt="shape" />
                                <i><HandWashIcon style={{width:"40"}}/></i>
                                
                            </div>
                            <div className="services-content mt-30">
                                <h4 className="services-title"><a href="#">Gentle wash</a></h4>
                                <p className="text">A.k.a. Hand Wash offered for delicate items, such as sportswear, undergarments, and other items that could easily damage or shrink in high heat drying process.</p>
                                {/* <a className="more" href="#">Learn More <i className="lni lni-chevron-right"></i></a> */}
                            </div>
                        </div> 
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-8">
                        <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
                            <div className="services-icon">
                                <img className="shape" src="assets/images/services-shape.svg" alt="shape" />
                                <img className="shape-1" src="assets/images/services-shape-2.svg" alt="shape" />
                                <i><DryCleaningIcon style={{width:"40"}} /></i>
                                
                            </div>
                            <div className="services-content mt-30">
                                <h4 className="services-title"><a href="#">Dry Cleaning</a></h4>
                                <p className="text">For items requiring dry cleaning or a perfectly-pressed finish, dry cleaning return with hangers.</p>
                                {/* <a className="more" href="#">Learn More <i className="lni lni-chevron-right"></i></a> */}
                            </div>
                        </div> 
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-8">
                        <div className="single-services text-center mt-30 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                            <div className="services-icon">
                                <img className="shape" src="assets/images/services-shape.svg" alt="shape" />
                                <img className="shape-1" src="assets/images/services-shape-3.svg" alt="shape" />
                                <i><HouseholdIcon style={{width:"40"}} /></i>
                            </div>
                            <div className="services-content mt-30">
                                <h4 className="services-title"><a href="#">Household Item</a></h4>
                                <p className="text">Curtain, comforter, bed sheets, towels, cushion cover, soft toy, shoes etc.</p>
                                {/* <a className="more" href="#">Learn More <i className="lni lni-chevron-right"></i></a> */}
                            </div>
                        </div> 
                    </div>
                </div> 
            </div> 
        </section>
        </>
    )
}