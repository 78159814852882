export default () => {
    return(
        <section id="prices" className="about-area pt-70">
        <div className="container">

        <div className="line m-auto"></div>
        <div className="row justify-content-center">
            <div className="section-title">
                <div className="line"></div>
                <h3 className="title">Laundry Pricing</h3>
            </div>
            <div className="col-md-6">
                <div className="single-services mt-10 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                    <h5><b>Wash + Dry + Fold</b></h5>
                    <br />
                    <ul className="leaders">
                        <li>
                            <span><b>Normal Wash</b></span>
                            <span>RM 5.80 / KG</span>
                        </li>
                    </ul>
                    <small><i>Washed with industry washer added premium detergent & softener. Perfect for your everyday laundry needs, including shirts, pants, towels, bedding, and more.</i></small>
                    <br /><br />
                    <small><i>Washed with industry washer added premium detergent & softener. Perfect for your everyday laundry needs, including shirts, pants, towels, bedding, and more.</i></small>
                    <br /><br />
                    <ul className="leaders">
                        <li>
                            <span><b>Gental Wash(Hand Wash)</b></span>
                            <span>RM 10.50 / KG</span>
                        </li>
                    </ul>    
                    <small><i>Cleaned by hand with premium detergent and softener, spin in extractor in low G-force (much more gentle compare to wings dry by hand). Perfect for delicate items, such as sportswear, undergarments, and other items that could easily damage or shrink in high heat drying process.</i></small>

                </div>
                <div className="single-services mt-10 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                    <h5><b>Dry + Fold</b></h5>
                    <br />
                    <ul className="leaders">
                        <li>
                            <span><b>Dry Only</b></span>
                            <span>RM 5.00 / KG</span>
                        </li>
                        <li>
                            <span><b>Household Item</b></span>
                            <span>70% of household item price</span>
                        </li>
                    </ul>    
                    <small><i>Spin in extractor, tumble dry later </i></small>

                </div>
                <div className="single-services mt-10 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                <h5><b>Household / Special Item </b></h5>
                <br />
                <ul className="leaders">                   
                    <li>
                        <span>Comforter Single/Queen</span>
                        <span>RM 20.00 / Pcs</span>
                    </li>
                    <li>
                        <span>Comforter King</span>
                        <span>RM 23.00 / Pcs</span>
                    </li>
                    <li>
                        <span>Pillow / Bolster Case</span>
                        <span>RM 2.00 /Pcs</span>
                    </li>
                    <li>
                        <span>Quilts / Bed Spread</span>
                        <span>RM 16.00 /Pcs</span>
                    </li>
                    <li>
                        <span>Quilts Cover / Bed Sheet</span>
                        <span>RM 12.00 / Pcs</span>
                    </li>
                    <li>
                        <span>Bedding Set </span>
                        <span>RM 48.00 / Set</span>
                    </li>
                    <small><i>(1 x Comforter + 1 Bedsheets + 1 x Bedspread + 5 Pillow Case)</i></small>
                    <li>
                        <span>Curtain</span>
                        <span>RM 12.80 / Kg</span>
                    </li>
                    <li>
                        <span>Curtain thin</span>
                        <span>RM 14.00 / Kg</span>
                    </li>
                    <li>
                        <span>Blanket / Cushion Cover / Table Cloth</span>
                        <span>RM 10.00 /Kg</span>
                    </li>
                    <li>
                        <span>floor Mat / Prayer Mat</span>
                        <span>RM 4.00 / ft<sup>2</sup></span>
                    </li>
                    <li>
                        <span>Carpet (thinkness within 2 cm)</span>
                        <span>RM 4.50 / ft<sup>2</sup></span>
                    </li>
                    <li>
                        <span>Carpet (thinkness within 4 cm)</span>
                        <span>RM 5.00 / ft<sup>2</sup></span>
                    </li>
                    <li>
                        <span>Soft Toy</span>
                        <span><small>from </small>RM 10.00 / pcs</span>
                    </li>
                    <small><i>Depending on size</i></small>
                    <li>
                        <span>Shoes</span>
                        <span>RM 29.00 / Pcs</span>
                    </li>


                    <li>
                        <span>Baby Car Seat / Stroller</span>
                        <span>RM 58.00 / Pcs</span>
                    </li>

                    <li>
                        <span>Soft Toy</span>
                        <span><small>from </small>RM 8.00 / Pcs</span>
                    </li>
                </ul>

                </div>
            </div> 
            <div className="col-md-6">
                <div className="single-services mt-10 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                     
                    <h5><b>Dry Cleaning</b></h5>
                    <br />
                    <ul className="leaders">
                        <li>
                            <span> Blouse / Shirt / T-Shirt / Trousers  </span>
                            <span>RM 14.00 / Pcs</span>
                        </li>
                        <li>
                            <span> Top Baju Kurong / Sweater / Skirt </span>
                            <span>RM 15.00 / Pcs</span>
                        </li>
                        <li>
                            <span> Coat </span>
                            <span>RM 17.00 / Pcs</span>
                        </li>
                        <li>
                            <span> Jacket </span>
                            <span>RM 21.00 / Pcs</span>
                        </li>
                        <li>
                            <span> Dress </span>
                            <span>RM 18.00 / Pcs</span>
                        </li>
                        <li>
                            <span> Long Dress / Chongsam </span>
                            <span>RM 24.00 / Pcs</span>
                        </li>
                        <li>
                            <span> Robe / Overcoat </span>
                            <span>RM 29.00 / Pcs</span>
                        </li>
                        <li>
                            <span> Tie </span>
                            <span>RM 10.00 / Pcs</span>
                        </li>
                        <li>
                            <span> Vest </span>
                            <span>RM 14.00 / Pcs</span>
                        </li>
                        <li>
                            <span> Bed Sheet </span>
                            <span>RM 23.00 / Pcs</span>
                        </li>
                        <li>
                            <span> Bed Spread </span>
                            <span>RM 43.00 / Pcs</span>
                        </li>
                        <li>
                            <span> Comforter Single / Queen </span>
                            <span>RM 53.00 / Pcs</span>
                        </li>
                        <li>
                            <span> Comforter King </span>
                            <span>RM 61.00 / Pcs</span>
                        </li>
                        <li>
                            <span>Blanket/ Cushion Cover / Table Cloth</span>
                            <span>RM 20.00 / Kg</span>
                        </li>
                        <li>
                            <span>Curtain</span>
                            <span>RM 21.00 / Kg</span>
                        </li>
                        <li>
                            <span>Hand Bag</span>
                            <span><small>From </small>RM 48.00 / Pcs</span>
                        </li>
                        <li>
                            <span> Evening Dress </span>
                            <span><small>From </small>RM 40.00 / Pcs</span>
                        </li>
                        <li>
                            <span>Wedding Dress</span>
                            <span><small>From </small>RM 70.00 / Pcs</span>
                        </li>
                    </ul>  
                </div>
                <div className="single-services mt-10 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                     
                     <h5><b>Ironing</b></h5>
                     <br />
                     <ul className="leaders">
                         <li>
                             <span><b>Shirt / T-Shirt / Trousers</b></span>
                             <span>RM 4.80 / Pcs</span>
                         </li>
                         <li>
                             <span><b>Coat / Dress</b></span>
                             <span>RM 7.50 / Pcs</span>
                         </li>
                         <li>
                             <span><b>Long Dress / Chongsam</b></span>
                             <span>RM 8.60 / Pcs</span>
                         </li>      
                     </ul>
                     <small>Discount <b>RM 0.80</b> per pcs for item that wash our laundry</small>
                 </div>
            </div>
        </div>  
        </div>
    </section>
    )
}