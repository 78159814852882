export default () => {
    return(
        <>
    <footer id="footer" className="footer-area pt-120">
        <div className="container">
            <div className="subscribe-area wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="subscribe-content mt-45">
                            <h2 className="subscribe-title">WhatsApp us  <span>for more details</span></h2>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="mt-50">
                            {/* <form action="#"> */}
                                {/* <input type="text" placeholder="Enter eamil" /> */}
                                <a className="btn btn-primary btn-lg btn-block" style={{backgroundColor:'green', color:"#fff", border:"0px"}} target="_blank" href="https://wa.me/message/IQTF74IMJ5PHE1">WhatsApp us now</a>
                            {/* </form> */}
                        </div>
                    </div>
                 </div> 
            </div> 
            <div className="footer-widget pb-100">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-8">
                        <div className="footer-about mt-50 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                            <a className="logo" href="#">
                                <img src="assets/images/wellclean_logo.png" alt="logo" />
                            </a>
                            <p className="text">In the laundry business since 1990. More than 20 years in the laundry & dry cleaning industry, specialize in all types of garment cleaning processes.</p>
                            <ul className="social">
                                <li><a href="https://www.facebook.com/wellCleanLaundry/"><i className="lni lni-facebook-filled"></i></a></li>
                                {/* <li><a href="#"><i className="lni lni-twitter-filled"></i></a></li> */}
                                <li><a href="https://www.instagram.com/wellclean_laundry"><i className="lni lni-instagram-filled"></i></a></li>
                                {/* <li><a href="#"><i className="lni lni-linkedin-original"></i></a></li> */}
                            </ul>
                        </div> 
                    </div>
                    <div className="col-lg-5 col-md-7 col-sm-7">
                        <div className="footer-link d-flex mt-50 justify-content-md-between">
                            {/* <div className="link-wrapper wow fadeIn" data-wow-duration="1s" data-wow-delay="0.4s">
                                <div className="footer-title">
                                    <h4 className="title">Quick Link</h4>
                                </div>
                                <ul className="link">
                                    <li><a href="#">Road Map</a></li>
                                    <li><a href="#">Privacy Policy</a></li>
                                    <li><a href="#">Refund Policy</a></li>
                                    <li><a href="#">Terms of Service</a></li>
                                    <li><a href="#">Pricing</a></li>
                                </ul>
                            </div>
                            <div className="link-wrapper wow fadeIn" data-wow-duration="1s" data-wow-delay="0.6s">
                                <div className="footer-title">
                                    <h4 className="title">Resources</h4>
                                </div>
                                <ul className="link">
                                    <li><a href="#">Home</a></li>
                                    <li><a href="#">Page</a></li>
                                    <li><a href="#">Portfolio</a></li>
                                    <li><a href="#">Blog</a></li>
                                    <li><a href="#">Contact</a></li>
                                </ul>
                            </div>  */}
                        </div> 
                    </div>
                    <div className="col-lg-3 col-md-5 col-sm-5">
                        <div className="footer-contact mt-50 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                            <div className="footer-title">
                                <h4 className="title">Contact Us</h4>
                            </div>
                            <ul className="contact">
                                <li>+6016 241 8309</li>
                                <li>support@WellClean.com.my</li>
                                <li>www.WellClean.com.my</li>
                                <li>Lot 355, Ground Floor Rubber Road <br /> 93300 Kuching, Sarawak Malaysia</li>
                            </ul>
                        </div> 
                    </div>
                </div> 
            </div> 
            <div className="footer-copyright">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="copyright d-sm-flex justify-content-between">
                            <div className="copyright-content">
                                <p className="text">Well Cleaning Services &copy; 2021 
                                {/* <a href="https://uideck.com" rel="nofollow">UIdeck</a> */}
                                </p>
                            </div> 
                        </div>
                    </div>
                </div> 
            </div> 
        </div> 
        <div id="particles-2"></div>
    </footer>
        
        </>
    )
}