import React, {useState, useEffect, useCallback} from "react";
import { useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import axios from "axios";
import {CHECK_BILL_URL} from '../lib/config'



export default () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [billNo, setBillNo] = useState('')
    const [billRes, setBillRes] = useState({ 
      status:'unknow',
      title:"No Resut Found",
      msg:"Please call or whatsApp 016-2418309 for assistance ",
    })
    const [step, setStep] = useState(0)
    const [reCapStatus, setReCapStatus] = useState(false)


    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            setReCapStatus(false)
          console.log('Execute recaptcha not yet available');
          return;
        }
        const token = await executeRecaptcha('yourAction');
        setReCapStatus(true)
        // Do whatever you want with the token
    }, [executeRecaptcha]);

    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    function handleChange(event) {
        setBillNo(event.target.value);
      }
    function handleBack(event) {
      event.preventDefault();
      setStep(0)
    }
    async function handleSubmit(event) {
      event.preventDefault();
      if(reCapStatus === false){
        alert("We can't varify your are a human")
        return
      } 
      const result = await axios.post(CHECK_BILL_URL, { bill: billNo},  {headers: {
        'Content-Type': 'application/json'
      }})
      setBillRes(result.data)
      setStep(1)
    }

    const renderForm = () => (
      <form onSubmit={handleSubmit}>
        <div className="text-center" >
          <input type="text" className="form-control form-control-lg text-center" id="colFormLabelLg" value={billNo} onChange={handleChange} placeholder="Enter Bill Number" />
          <p className="card-text"><small className="text-muted">Bill number is located on the top right of your bill</small></p>
          <button type="submit" className="btn btn-primary btn-lg btn-block mt-4">Check Bill</button> 
       </div>
    </form> 
    )

    const renderResult = () => {
      return (
        <div className="text-center" >
          <h5 className="text-uppercase"><b>{billRes.title}</b></h5>
          <p className="font-weight-normal">{billRes.msg}</p>
          <small className="text-warning font-italic"><i>
          if you have sent in your laundry for more than 3 days but your status is still shown as processing, please call or WhatsApp us at 016-241 8309 to check your laundry status, thank you.
            </i></small>
          <button type="submit" className="btn btn-primary btn-lg btn-block mt-4" onClick={handleBack}>Check another bill</button> 
        </div>
      )
    }

    return (
      <div className="p-4 wow fadeIn col-lg-6 col-md-12 h-100 mr-auto ml-auto" style={{background:"rgba(255,255,255,0.9)", borderRadius:15}} >
        { step === 1 ? renderResult() : renderForm()}
      </div>
    )

    
}