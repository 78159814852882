import { Link } from "react-router-dom";
import CheckBillForm from "./checkBillForm";

export default () => {
    return(
        <>  
        <header className="header-area">
            <div className="navbar-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <nav className="navbar navbar-expand-lg">
                                <a className="navbar-brand" href="/">
                                    <img src="assets/images/wellclean_logo.png" alt="Logo" />
                                </a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="toggler-icon"></span>
                                    <span className="toggler-icon"></span>
                                    <span className="toggler-icon"></span>
                                </button>
    
                                <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                    <ul id="nav" className="navbar-nav ml-auto">
                                        <li className="nav-item active">
                                            <Link to="/" rel="nofollow">Home</Link>
                                        </li>
                                    </ul>
                                </div>
                                
                                <div className="navbar-btn d-none d-sm-inline-block">
                                     <Link className="main-btn" data-scroll-nav="0" to="/checkbill" rel="nofollow">Check Bill Status</Link>
                                </div>
                            </nav> 
                        </div>
                    </div>
                </div>
            </div> 
            
            <div id="home" className="header-hero bg_cover" 
            style={{backgroundImage: "url(assets/images/banner-bg.svg)" }}
            >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="header-hero-content text-center">
                                {/* <h3 className="header-sub-title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s">Check </h3> */}
                                <h2 className="header-title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.5s">Check Your Bill Status</h2>
                                <p className="text wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.8s">
                                    More than 20 years in laundry & dry cleaning business -- the brand you trust.
                                    {/* Give yourself some rest! Let us handle your laundry. */}
                                </p>
                                
                                {/* <a href="#" className="main-btn wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="1.1s">Schedule pickup</a> */}
                            </div> 
                        </div>
                    </div> 
                    <div className="row">
                        <div className="col-lg-12">
                            <div style={{minHeight:300}} className="header-hero-image text-center wow fadeIn" data-wow-duration="1.3s" data-wow-delay="1.4s">
                                <CheckBillForm />
                                {/* <img src="assets/images/WellCleanMainPic.png" alt="hero" /> */}
                            </div> 
                        </div>
                    </div> 
                </div> 
                <div id="particles-1" className="particles"></div>
            </div>
        </header>
        
        </>
    )
}