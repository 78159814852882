import { Link } from "react-router-dom";

export default () => {
    return(
        <>
        {/* <div className="preloader">
            <div className="loader">
                <div className="ytp-spinner">
                    <div className="ytp-spinner-container">
                        <div className="ytp-spinner-rotator">
                            <div className="ytp-spinner-left">
                                <div className="ytp-spinner-circle"></div>
                            </div>
                            <div className="ytp-spinner-right">
                                <div className="ytp-spinner-circle"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}

        
        <header className="header-area">
            <div className="navbar-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <nav className="navbar navbar-expand-lg">
                                <a className="navbar-brand" href="/">
                                    <img src="assets/images/wellclean_logo.png" alt="Logo" />
                                </a>
                                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="toggler-icon"></span>
                                    <span className="toggler-icon"></span>
                                    <span className="toggler-icon"></span>
                                </button>
    
                                <div className="collapse navbar-collapse sub-menu-bar" id="navbarSupportedContent">
                                    <ul id="nav" className="navbar-nav ml-auto">
                                        <li className="nav-item active">
                                            <a className="page-scroll" href="#home">Home</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="page-scroll" href="#service">Service</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="page-scroll" href="#prices">Price List</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="page-scroll" href="#location">Our Branch</a>
                                        </li>
                                         <li className="nav-item  d-block d-sm-none ">
                                            {/* <a className="page-scroll" href="#facts">Why</a> */}
                                            <Link className="main-btn" data-scroll-nav="0" to="/checkbill" rel="nofollow">Check Bill Status</Link>
                                        </li>
                                        {/*<li className="nav-item">
                                            <a className="page-scroll" href="#team">Team</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="page-scroll" href="#blog">Blog</a>
                                        </li> */}
                                    </ul>
                                </div>
                                
                                <div className="navbar-btn d-none d-sm-inline-block">
                                    {/* <a className="main-btn" data-scroll-nav="0" href="https://uideck.com/templates/basic/" rel="nofollow">Check Bill Status</a>
                                     */}
                                     <Link className="main-btn" data-scroll-nav="0" to="/checkbill" rel="nofollow">Check Bill Status</Link>
                                </div>
                            </nav> 
                        </div>
                    </div>
                </div>
            </div> 
            
            <div id="home" className="header-hero bg_cover" 
            style={{backgroundImage: "url(assets/images/banner-bg.svg)" }}
            >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="header-hero-content text-center">
                                <h3 className="header-sub-title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.2s">20 years in the laundry business</h3>
                                <h2 className="header-title wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.5s">Your Laundry Expert</h2>
                                <p className="text wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.8s">
                                    {/* More than 20 years in laundry & dry cleaning business -- the brand you trust. */}
                                    Give yourself some rest! Let us handle your laundry.
                                </p>
                                {/* <a href="#" className="main-btn wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="1.1s">Schedule pickup</a> */}
                            </div> 
                        </div>
                    </div> 
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="header-hero-image text-center wow fadeIn" data-wow-duration="1.3s" data-wow-delay="1.4s">
                                <img src="assets/images/WellCleanMainPic.png" alt="hero" />
                            </div> 
                        </div>
                    </div> 
                </div> 
                <div id="particles-1" className="particles"></div>
            </div>
        </header>
        
        </>
    )
}