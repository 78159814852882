import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Header from './components/header'
import HeaderCheckBill from './components/headerCheckBill'
import Preloader from './components/preloader'
import Footer from './components/footer'
import Brands from './components/brands'
import Location from './components/location'
import Service from './components/service'
import Why from './components/why'
import Price from './components/prices'
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import {reCapKey} from './lib/config'

function Home() {
  return (
    <>
    <Header />
    {/* <Brands /> */}
    <Service />
    <Price />
    <Location />
    {/* <Why /> */}
    <Footer />
    </>
  );
}

function CheckBill() {
  return (
    <>
    <HeaderCheckBill />
    {/* <Brands /> */}
    {/* <Service />
    <Price /> */}
    {/* <Location /> */}
    {/* <Why /> */}
    <Footer />
    </>
  );
}

function App(){
  return (
    <>
    <BrowserRouter>
      <Preloader />
      <GoogleReCaptchaProvider 
        reCaptchaKey={reCapKey}>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/home' element={<Home/>} />
        <Route path='/checkbill' element={<CheckBill/>} />
      </Routes>
      </GoogleReCaptchaProvider>
    </BrowserRouter>
    </>
  )
}


export default App;
